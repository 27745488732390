<template>
  <div>
    <Header>
      <template v-slot:title>
        {{ $t("Summary") }}
      </template>
    </Header>
    <div
      v-if="get_ib_profile.state === 'Approved'"
      class="bg-white shadow rounded-md min-h-screen m-auto max-w-7xl p-0 sm:mt-5"
    >
      <div>
        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="align-middle inline-block min-w-full">
              <div class="overflow-hidden border-b border-gray-200">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("Clients") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("total-commission-earned") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("Balance") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("Deposits") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("Withdrawals") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-if="get_ib_summary.payment_info && get_ib_profile">
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ get_ib_summary.invited }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        <money-format
                          :currency-code="get_ib_profile.currency"
                          :value="get_ib_summary.total_commissions"
                        >
                        </money-format>
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        <money-format
                          :currency-code="get_ib_profile.currency"
                          :value="get_ib_summary.payment_info.balance"
                        >
                        </money-format>
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        <money-format
                          :currency-code="get_ib_profile.currency"
                          :value="get_ib_summary.total_deposit"
                        >
                        </money-format>
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        <money-format
                          :currency-code="get_ib_profile.currency"
                          :value="get_ib_summary.total_withdrawal"
                        >
                        </money-format>
                      </td>
                      <!--                    <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">-->
                      <!--                      <money-format :value="payment.value"-->
                      <!--                                    :currency-code='payment.currency'>-->
                      <!--                      </money-format>-->
                      <!--                    </td>-->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ib-approval-alert v-if="get_ib_profile.state === 'Pending'" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MoneyFormat from "vue-money-format";
import Header from "@/components/Header";
import IbApprovalAlert from "@/components/IbApprovalAlert";

export default {
  name: "Summary",
  components: {
    "money-format": MoneyFormat,
    Header,
    IbApprovalAlert,
  },
  computed: {
    ...mapGetters(["get_ib_summary", "get_ib_profile"]),
  },
  created() {
    this.$store.dispatch("summary");
    this.$store.dispatch("ib_profile");
  },
};
</script>